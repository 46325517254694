import { ArrowLeftOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import { setLoading } from "../../store/slices/auth";
import { deleteTemplate, getDocument, getDocumentList, getGroups } from "./apiController";
import { CustomButton, showToaster, toasterTypes } from "../../widgets";
import { FieldItem } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import CreateDocument from "./CreateDocument";
import Editor from "../Editor/Editor";
import ModalComponent from "../common/Modal/modal";
import { copyData } from "../../utils/Utils";
import Tabs from "../sampleSize/Tabs";
import { TabsData } from "../../utils/TabsData";

export default function Documents(props) {
     const navigate = useNavigate();
     const location = useLocation();
     const dispatch = useDispatch();
     const [groups, setgroups] = useState<any | null>([]);
     const [documentList, setDocumentList] = useState<any | null>([]);
     const [documentData, setDocumentData] = useState<any | null>([]);
     const [groupid, SetgroupId] = useState("");
     const [popUpType, setPopUpType] = useState("");
     const [selectedId, setSelectedId] = useState("");
     const [projectData, setprojectData] = useState<any | null>(null);
     const [values, fields, handleChange, { validateValues, updateValue }] = useFieldItem({}, {}, { onValueChange });
     const [isEdit, setIsEdit] = useState<any>({})

     /**
    * handle on change values of form elements
    * @param name 
    * @param value 
    * @param values 
    * @param fieldValues 
    * @returns 
    */
     function onValueChange(name, value, values, fieldValues = null) {
          const resetValue = {}
          console.log(values)

          return [{
               ...values,
               ...resetValue,
               [name]: value,
          }]
     }

     useEffect(() => {
          if (location.state) {
               let tempState: any = location.state;
               getDocuments();
               setprojectData(tempState);
               console.log(tempState)
               let temp: any = localStorage.getItem("coguide_user");
               if (temp) {
                    temp = JSON.parse(temp);
                    fetchGroups(tempState.TopicID || tempState.TopicCode);
               }
          } else {
               navigate("/project");
          }

     }, [location.state, navigate]);

     /**
   * fetch groups data
   */
     const fetchGroups = async (id) => {
          try {
               dispatch(setLoading(true));
               let tempGroups = await getGroups(id);
               tempGroups?.data.forEach((obj) => {
                    obj.label = obj.name;
                    obj.value = obj.id;
               });
               setgroups(tempGroups?.data || []);
               let groupIndex = tempGroups?.data.findIndex((obj) => obj.is_default);
               if (groupIndex >= 0) {
                    SetgroupId(tempGroups?.data[groupIndex].id);
               } else {
                    SetgroupId(tempGroups?.data[0].id);
               }

               dispatch(setLoading(false));
          } catch (e: any) {
               setgroups([]);
               dispatch(setLoading(false));
          }
     };

     /**
      * get documents list
      */
     const getDocuments = async () => {
          let tempState: any = location.state;
          console.log(tempState)
          try {
               let documentResponse = await getDocumentList(tempState.id);
               setDocumentList(documentResponse)
          } catch (error) {
               throw error
          }

     }

     const onClose = () => {
          setPopUpType("")
          setIsEdit({})
          getDocuments()
     }

     const onDelete = async (id) => {
          console.log(id)
          let deleteDoc = await deleteTemplate(id);
          if (deleteDoc) {
               getDocuments();
               showToaster(toasterTypes.INFO, "Document deleted successfull")
          }
          setPopUpType("")
     }

     /**
      * get document data by its ID
      * @param id 
      */
     const getDocumentData = async (id) => {
          let documentDataResponse = await getDocument(id);
          setDocumentData(documentDataResponse.data)
          console.log(documentDataResponse)
          localStorage.setItem("documentSelected", id)
          let getDocumentDataLocal: any = JSON.parse(localStorage.getItem(id) || "[]")
          if (getDocumentDataLocal && getDocumentDataLocal.length == 0) {

               documentDataResponse.data.revision.content ? localStorage.setItem(id, documentDataResponse && JSON.stringify(documentDataResponse.data.revision.content)) : localStorage.setItem(id,JSON.stringify({content:[],type:"doc"}))
               localStorage.setItem("docData", documentDataResponse && JSON.stringify(documentDataResponse.data.revision.content))
          }
          //setPopUpType("editor")
          let tempState: any = location.state;
          //set docInfo to localstorage
          localStorage.setItem("docInfo",JSON.stringify({docInfo: documentDataResponse.data}))
          navigate('/editor', { state: { docInfo: documentDataResponse.data, data: tempState } })

     }

     /**
      * get document link
      */
     const getDocumentLink = (id) => {
          let DocLink = `${window.location.href}?docId=${id}`
          copyData(DocLink, true)
          showToaster(toasterTypes.SUCCESS, "Link Copied Successfully");
          // navigate('/editor',{state:"http://localhost:3000/login?docId=66c627efe606d657b27df6bc"})
     }

     /**
      * Edit data
      */
     const onEditAction = (data) => {
          setIsEdit(data)
          setPopUpType("create")
     }

     useEffect(() => {

     }, [documentList])

     return (
          <>
               <div className="documents-data-container">
                    {
                         popUpType == "create" &&
                         <CreateDocument projectData={projectData} getDocuments={getDocuments} onClose={onClose} prevData={isEdit} />
                    }

                    {
                         popUpType == "delete" &&
                         <ModalComponent
                              width={"30%"}
                              onClose={() => setPopUpType("")}
                              isOpen={true}
                              header={<b>Delete Document</b>}
                              centered={true}
                              customClass="document-modal"
                              body={
                                   <>
                                        <p>Once deleted, this action cannot be undone. Are you sure you want to delete?</p>

                                   </>
                              }
                              footer={
                                   <CustomButton type={"primary"} text="Delete" onClick={() => onDelete(selectedId)} />
                              }
                         />
                    }

                    <>
                         <Row className='page-header m-0'>
                              <Col className={window.innerWidth > 750 ? "project-title" : "project-title-mob"} md={12}>
                                   <ArrowLeftOutlined style={{ color: "#fff", fontSize: "24px", margin: "0px 10px" }} onClick={() => window.innerWidth > 750 ? navigate("/project") : navigate("/projectmobile")} />
                                   {projectData?.TopicDesc || projectData?.topicdesc}
                              </Col>
                              <Col className='p-0'></Col>
                         </Row>
                         <Tabs data={TabsData} selectedTab={7} projectData={projectData} />

                         <div className="documents-data">
                              <Row className="m-3">
                                   <Col className="p-0 d-flex m-0" md={10} xs={12}>
                                        <div className="header mr-4 pt-0">Documents</div>
                                        <FieldItem
                                             name="search"
                                             value={values.search}
                                             onChange={(...e) => handleChange("search", ...e)}
                                             touched={fields.search && fields.search.hasError}
                                             error={fields.search && fields.search.errorMsg}
                                        />
                                   </Col>
                                   <Col className="p-0 d-flex m-0" md={2} xs={12}>
                                        <CustomButton
                                             className="mt-2"
                                             type="alert-primary"
                                             text="Create"
                                             onClick={() => setPopUpType("create")}
                                        ></CustomButton>
                                   </Col>
                              </Row>

                         </div>
                         <div className="documents-container">
                              <div className="documents-list-container m-3">

                                   {
                                        documentList && documentList.map((item, i) => {

                                             return (
                                                  <Card className="document-card" key={i}>
                                                       <CardHeader>
                                                            <Row>
                                                                 <Col md="9">
                                                                      <span className="title">{item.name}</span>
                                                                 </Col>
                                                                 <Col md="3">
                                                                      V<span className="version">{item.versions_count}</span>
                                                                 </Col>
                                                            </Row>
                                                       </CardHeader>
                                                       <CardBody>
                                                            <span className="description">{item.desc}</span>
                                                       </CardBody>
                                                       <CardFooter className="footer-card">
                                                            <div className="document-actions">
                                                                 <span className="mr-3">
                                                                      <span className="apply-txt" style={{ color: "#006bd9", cursor: "pointer" }} onClick={() => getDocumentData(item.id)} >View</span>
                                                                 </span>

                                                                 <span className="mr-3">
                                                                      <i className="icon icon-edit" onClick={() => onEditAction(item)}></i>
                                                                 </span>
                                                                 <span className="mr-3">
                                                                      <i className="icon icon-delete" onClick={() => { setSelectedId(item.id); setPopUpType("delete") }}></i>
                                                                 </span>

                                                            </div>
                                                       </CardFooter>
                                                  </Card>
                                             )
                                        })
                                   }
                              </div>
                         </div>
                    </>

               </div>
          </>
     )
}