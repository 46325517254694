import { Service } from 'wax-prosemirror-core';
import ColorMark from './schema/ColorMark';
import FontSize from './schema/FontSize';
import FontFamilyMark from './schema/FontFamilyMark';
import LineHeight from './schema/LineHeight';
import TextAlignment from './schema/FontAlignment';
import TextTypoSection from './TextTypoSection';

export default class TextTypoService extends Service {

  boot() {
    const layout = this.container.get('Layout');

    layout.addComponent('textTypoSection', TextTypoSection);
  }

  register() {
    const textTypoSection = this.config.get('config.TextTypoSection');
    const createMark = this.container.get('CreateMark');
    createMark(
      {
        colorText: ColorMark,
      },
      { toWaxSchema: true },
    );

    createMark(
      {
        fontSize: FontSize,
      },
      { toWaxSchema: true },
    );

    createMark(
      {
        fontFamilyText: FontFamilyMark,
      },
      { toWaxSchema: true },
    );

    createMark(
      {
        lineHeightText: LineHeight,
      },
      { toWaxSchema: true },
    );

    createMark(
      {
        textAlignment: TextAlignment,
      },
      { toWaxSchema: true },
    );
  }
}
