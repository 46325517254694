import React, {
  useContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import 'wax-prosemirror-core/dist/index.css';
import 'wax-prosemirror-services/dist/index.css';
import 'wax-table-service/dist/index.css';
import styled, { css, ThemeProvider } from 'styled-components';
import PanelGroup from 'react-panelgroup';

import {
  WaxContext,
  ComponentPlugin,
  DocumentHelpers,
  WaxView
} from 'wax-prosemirror-core';
import { grid, th } from '@pubsweet/ui-toolkit';
import { cokoTheme } from '../theme';
import EditorElements from './EditorElements';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Col, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { showToaster, toasterTypes } from '../../../widgets';
import { postRevisedData } from '../../documents/apiController';


const divider = css`
  .panelGroup {
    background: #fff;
  }
  .divider {
    > div {
      background: ${th('colorBorder')};
      height: ${grid(1)};
      max-height: ${grid(1)};

      &:hover {
        height: ${grid(2)};
        max-height: ${grid(2)};
      }
    }
  }
`;

const Wrapper = styled.div`
  background: ${th('colorBackground')};
  font-family: ${th('fontInterface')};
  font-size: ${th('fontSizeBase')};
  line-height: ${grid(4)};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: hidden;

  ${divider}

  * {
    box-sizing: border-box;
  }
`;

const Main = styled.div`
  display: flex;
  flex-grow: 1;
  height: 500px;  /* Or a desired fixed height */
  overflow-y: auto;
  margin-top:125px;
`;


const TopHead = styled.div`
display:flex;
flex-direction:column;
width:100%;
position:fixed;
z-index:1;
background: blue;
border-top: ${th('borderWidth')} ${th('borderStyle')} ${th('colorBorder')};
border-bottom: ${th('borderWidth')} ${th('borderStyle')} ${th('colorBorder')};
`;

const TopMenu = styled.div`
  display: flex;
  min-height: 40px;
  user-select: none;
  background: ${th('colorBackgroundToolBar')};
  border-top: ${th('borderWidth')} ${th('borderStyle')} ${th('colorBorder')};
  border-bottom: ${th('borderWidth')} ${th('borderStyle')} ${th('colorBorder')};

  > div:not(:last-child) {
    border-right: ${th('borderWidth')} ${th('borderStyle')}
      ${th('colorFurniture')};
  }

  > div:nth-last-of-type(-n + 2) {
    margin-left: auto;
  }

  > div:last-child {
    margin-left: 0;
    margin-right: 10;
  }

  > div[data-name='Tables'] {
    border-right: none;
  }
`;

const SideMenu = styled.div`
  background: ${th('colorBackgroundToolBar')}
  border-right: ${th('borderWidth')} ${th('borderStyle')} ${th('colorBorder')};
  min-width: 250px;
  height: calc(100vh - 16px);
  position:fixed;
  left:0px;
`;

const EditorArea = styled.div`
  flex-grow: 1;
`;

const WaxSurfaceScroll = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  box-sizing: border-box;
  width: 80%;
  height: 100%;
  position: absolute;
  left: 20vw;
  
  /* PM styles for main content */
  ${EditorElements};

  /* Custom scrollbar for WebKit browsers (Chrome, Safari) */
  ::-webkit-scrollbar {
    width: 12px; /* Set width of the vertical scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: #fff; /* Background color of the scrollbar track */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #fff; /* Color of the scrollbar thumb */
    border-radius: 10px;    /* Rounded corners for the thumb */
    border: 3px solid #fff; /* Optional: add a border to thumb */
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #fff; /* Darker color on hover */
  }

  /* Custom scrollbar for Firefox */
  scrollbar-width: thin; /* Make the scrollbar thin */
  scrollbar-color: #fff #fff; /* Thumb color and track background */

  /* Optional: Add padding or margins if needed for scrollable content */
  padding: 16px;
`;


const WaxSurfaceScroll1 = styled.div`
  overflow-y: auto;
  display: flex;
  box-sizing: border-box;
  height: calc(100vh-300px);
  width: 40%;
  position: absolute;
  /* PM styles  for main content*/
  ${EditorElements};
`;

const EditorContainer = styled.div`
  width: 80%;
  height: 100%;

  .ProseMirror {
    box-shadow: 0 0 8px #ecedf1;
    min-height: 98%;
    padding: ${grid(10)};
  }
`;

const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
  height: 100%;
`;

const CommentsContainerNotes = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
  height: 100%;
`;

const CommentTrackToolsContainer = styled.div`
  display: flex;
  position: fixed;
  padding-top: 5px;
  right: 30px;
  z-index: 1;
  background: white;
  width: 25%;
`;

const CommentTrackTools = styled.div`
  margin-left: auto;
  display: flex;
  position: relative;
  z-index: 1;
`;

const CommentTrackOptions = styled.div`
  display: flex;
  margin-left: 10px;
  bottom: 5px;
  position: relative;
`;

const NotesAreaContainer = styled.div`
  background: #fff;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  position: absolute;
  /* PM styles  for note content*/
  .ProseMirror {
    display: inline;
  }
`;

const NotesContainer = styled.div`
  counter-reset: footnote-view;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: ${grid(4)};
  padding-left: ${grid(10)};
  height: 100%;
  width: 65%;
  ${EditorElements};
`;
const WaxBottomRightInfo = styled.div``;
const InfoContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 1px;
  right: 21px;
  z-index: 999;
`;

let surfaceHeight = (window.innerHeight / 5) * 3;
let notesHeight = (window.innerHeight / 5) * 2;

const onResizeEnd = arr => {
  surfaceHeight = arr[0].size;
  notesHeight = arr[1].size;
};

const getNotes = main => {
  const notes = DocumentHelpers.findChildrenByType(
    main.state.doc,
    main.state.schema.nodes.footnote,
    true,
  );
  return notes;
};

// const LeftSideBar = ComponentPlugin('leftSideBar');
const TopBar = ComponentPlugin('topBar');
const NotesArea = ComponentPlugin('notesArea');
const OutlineArea = ComponentPlugin('outlineArea');
const TextTypoSection = ComponentPlugin('textTypoSection');
const VersionConfig = ComponentPlugin('versionSection');
const RightArea = ComponentPlugin('rightArea');
const CommentTrackToolBar = ComponentPlugin('commentTrackToolBar');
const BottomRightInfo = ComponentPlugin('BottomRightInfo');

const EditoriaLayout = (props, { editor }) => {
  const {
    pmViews: { main },
    options,
  } = useContext(WaxContext);

  let fullScreenStyles = {};

  if (options.fullScreen) {
    fullScreenStyles = {
      backgroundColor: '#fff',
      height: '100%',
      left: '0',
      margin: '0',
      padding: '0',
      position: 'fixed',
      top: '0',
      width: '100%',
      zIndex: '99999',
    };
  }
  const notes = main && getNotes(main);
  const commentsTracksCount =
    main && DocumentHelpers.getCommentsTracksCount(main);
  const trackBlockNodesCount =
    main && DocumentHelpers.getTrackBlockNodesCount(main);

  const areNotes = notes && !!notes.length && notes.length > 0;

  const [hasNotes, setHasNotes] = useState(areNotes);
  const navigate = useNavigate();
  const showNotes = () => {
    setHasNotes(areNotes);
  };

  const delayedShowedNotes = useCallback(
    setTimeout(() => showNotes(), 100),
    [],
  );

  useEffect(() => { }, [delayedShowedNotes]);

  const [project, setProject] = useState(JSON.parse(localStorage.getItem('docInfo')))
  // const users = [{
  //   userId: '1',
  //   displayName: 'test test',
  //   currentUser: true,
  // }]

  useEffect(() => {
    console.log("asdf")
  }, [])

  /**
   * on save
   * create json format 
   */
  const onSaveDocument = async () => {
    let data = JSON.parse(localStorage.getItem('projectData'));
    let docId = localStorage.getItem("documentSelected")
    let parsedJSON = JSON.parse(localStorage.getItem(docId));

    let Obj = {
      "topic_id": data.TopicID,
      "project_id": data.id,
      "doc_id": docId,
      "content": parsedJSON
    }

    try {
      let saveDocResponse = await postRevisedData(Obj);
      if (saveDocResponse) {
        showToaster(toasterTypes.SUCCESS, "Document saved successfully!!")
      }
    } catch (error) {
      throw error;
    }
  }


  /**
  * onclick documents
  */
  const onDocumentAction = async () => {
    onSaveDocument();
    navigate('/project', { state: { data: JSON.parse(localStorage.getItem('projectData')), type: "document" } })

  }

  return (
    <>
      <ThemeProvider theme={cokoTheme}>

        <Wrapper style={fullScreenStyles} id="wax-container">
            <TopHead>
              <Row className='page-header m-0' >
                <Col className={window.innerWidth > 750 ? "project-title" : "project-title-mob"} md={11}>
                  <ArrowLeftOutlined style={{ color: "#fff", fontSize: "24px", margin: "0px 10px" }} onClick={() => onDocumentAction()} />
                  <span style={{ color: "#fff", fontSize: "24px", margin: "0px 10px" }}>{project && project.docInfo.name}</span>

                </Col>
                <Col className='p-0'>
                  {/* <CustomButton text="Save" onClick={() => onSaveDocument()} /> */}
                </Col>
              </Row>

              <TopMenu>
                <VersionConfig />
                <TextTypoSection />
                <TopBar />
              </TopMenu>
            </TopHead>
            <Main>
              <SideMenu>
                <OutlineArea />
                {/* <TextColor/> */}
              </SideMenu>
              <EditorArea>
                <WaxSurfaceScroll>
                  <EditorContainer>
                    <WaxView {...props} />
                  </EditorContainer>
                </WaxSurfaceScroll>
              </EditorArea>
              <EditorArea>
                <PanelGroup
                  direction="column"
                  panelWidths={[
                    { size: surfaceHeight, resize: 'stretch' },
                    { size: notesHeight, resize: 'resize' },
                  ]}
                  onResizeEnd={onResizeEnd}
                >
                  <WaxSurfaceScroll1>
                    <EditorContainer>{editor}</EditorContainer>
                    <CommentsContainer>
                      <CommentTrackToolsContainer>
                        <CommentTrackTools>
                          {commentsTracksCount + trackBlockNodesCount} COMMENTS AND
                          SUGGESTIONS
                          <CommentTrackOptions>
                            <CommentTrackToolBar />
                          </CommentTrackOptions>
                        </CommentTrackTools>
                      </CommentTrackToolsContainer>
                      <RightArea area="main" />
                    </CommentsContainer>
                  </WaxSurfaceScroll1>

                  {hasNotes && (
                    <NotesAreaContainer>
                      <NotesContainer id="notes-container">
                        <NotesArea view={main} />
                      </NotesContainer>
                      <CommentsContainerNotes>
                        <RightArea area="notes" />
                      </CommentsContainerNotes>
                    </NotesAreaContainer>
                  )}
                </PanelGroup>
              </EditorArea>
            </Main>
          <WaxBottomRightInfo>
            <InfoContainer id="info-container">
              <BottomRightInfo />
            </InfoContainer>

          </WaxBottomRightInfo>
        </Wrapper>
      </ThemeProvider>
    </>
  );
};

export default EditoriaLayout;
