import { useEffect, useState, useRef } from 'react'
import GanttChart from '../common/charts/GanttChart'
import { getProjectTeamList, getTask, saveComment, updateTask } from './apiController';
import { useDispatch } from "react-redux";
import { setLoading } from '../../store/slices/auth';
import { CreateFormElements } from './PlannerFormElements';
import { Col, Row } from 'reactstrap';
import ModalComponent from '../common/Modal/modal';
import { FieldItem, FIELD_TYPES } from "../../widgets/fields";
import useFieldItem from "../../widgets/fields/UseFieldItem";
import { CustomButton, showToaster, toasterTypes } from '../../widgets';
import html2canvas from 'html2canvas';


const ViewGanttChart = (props) => {
  const [scale, setScale] = useState<string>("years");
  const [tasks, setTasks] = useState<any>([]);
  const [teamData, setTeamData] = useState<any>([]);
  const [actualBarsVisible, setActualBarsVisible] = useState<boolean>(true);
  const [action, setaction] = useState<any | "">("");
  const [startrangedate, setStartrangedate] = useState<any>();
  const [endrangedate, setEndrangedate] = useState<any>();
  const [currentSelectedTask, setCurrentSelectedTask] = useState<any>({
    actualStart : null,
    actualEnd : null
  });
  const dispatch = useDispatch();

  const downloadChartRef = useRef(null);

  const FormElements = {
    formTypes: {
      name: "formType",
      placeholder: "Form Type",
      label: "",
      type: FIELD_TYPES.RADIO_BUTTON,
      values: [
        { label: "Download with both Planned and Actual Timelines", value: "withactualbars" },
        { label: "Download with only Planned Timeline", value: "withoutactualbars" },
      ],
      isMandatory: true,
    },
    actualstartdate: {
      name: "actualstartdate",
      placeholder: "Actual Start Date",
      label: "Actual Start Date",
      type: FIELD_TYPES.DATE_TIME_PICKER,
      isMandatory: false,
    },
    actualenddate: {
      name: "actualenddate",
      placeholder: "Actual End Date",
      label: "Actual End Date",
      type: FIELD_TYPES.DATE_TIME_PICKER,
      isMandatory: false,
    },
  };

  const [values, fields, handleChange, { validateValues, updateValue }] =
    useFieldItem(FormElements, {}, { onValueChange });


  function onValueChange(name, value, values, fieldValues = null) {
    if (name == "formType") {
      setaction(value);
      if (value === "withactualbars") {
        downloadChart(true);
      } else if (value === "withoutactualbars") {
        downloadChart(false);
      }
    }

    if(name === "actualstartdate" || name === "actualenddate"){
      let resetValue = {};
      console.log(value)
    return [
      {
        ...values,
        ...resetValue,
        [name]: value,
      },
    ];
    }

    if (name == "startrange") {
      if (value > endrangedate) {
        return;
      }

      if (value < new Date(props.formData.plan_start_date)) {
        setStartrangedate(new Date(props.formData.plan_start_date))
        return;
      }
      setStartrangedate(value)

      const endDate = new Date(endrangedate);
      const startDate = new Date(value);
      if (scale === "days") {

        const monthDifference = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());

        if (monthDifference > 1 || (monthDifference === 1 && endDate.getDate() > startDate.getDate())) {
          return;
        }
      } else if (scale === "months") {
        const monthDifference = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());

        if (monthDifference > 4 || (monthDifference === 4 && endDate.getDate() > startDate.getDate())) {
          return;
        }
      } else if (scale === "years") {
        const yearDifference = endDate.getFullYear() - startDate.getFullYear();

        if (yearDifference > 5 || (yearDifference === 5 && (endDate.getMonth() > startDate.getMonth() || (endDate.getMonth() === startDate.getMonth() && endDate.getDate() > startDate.getDate())))) {
          return;
        }
      }

    }
    if (name == "endrange") {
      if (value < startrangedate) {
        return;
      }
      if (value > new Date(props.formData.plan_end_date)) {
        setEndrangedate(new Date(props.formData.plan_end_date))
        return;
      }
      setEndrangedate(value)
      const startDate = new Date(startrangedate);
      const endDate = new Date(value);
      if (scale === "days") {
        const monthDifference = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());

        if (monthDifference > 1 || (monthDifference === 1 && endDate.getDate() > startDate.getDate())) {
          return;
        }
      } else if (scale === "months") {
        const monthDifference = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());

        if (monthDifference > 4 || (monthDifference === 4 && endDate.getDate() > startDate.getDate())) {
          return;
        }
      } else if (scale === "years") {
        const yearDifference = endDate.getFullYear() - startDate.getFullYear();

        if (yearDifference > 5 || (yearDifference === 5 && (endDate.getMonth() > startDate.getMonth() || (endDate.getMonth() === startDate.getMonth() && endDate.getDate() > startDate.getDate())))) {
          return;
        }
      }

    }
  }


  function getScale() {
    const dates = props.formData.tasks.flatMap(task => {
      const plannedStartDate = new Date(task.planned_start_date).getTime();
      const plannedEndDate = new Date(task.planned_end_date).getTime();
      const actualStartDate = task.actual_start_date ? new Date(task.actual_start_date).getTime() : null;
      const actualEndDate = task.actual_end_date ? new Date(task.actual_end_date).getTime() : null;

      return [plannedStartDate, plannedEndDate, actualStartDate, actualEndDate].filter(date => date !== null)
    });

    if (dates.length === 0) {
      console.error('No valid dates found');
      return null;
    }

    const minDate = new Date(Math.min(...dates));
    const maxDate = new Date(Math.max(...dates));

    const timeDiff = Math.abs(maxDate.getTime() - minDate.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));


    if (diffDays <= 30) {
      // Check if minDate and maxDate are in the same month and year
      const sameMonthAndYear = minDate.getFullYear() === maxDate.getFullYear() && minDate.getMonth() === maxDate.getMonth();

      setScale(sameMonthAndYear ? 'days' : 'months');

    } else if (diffDays <= 150) {
      setScale('months');
    } else {
      setScale('years');
    }

  }

  async function getTeamData() {
    try {
      dispatch(setLoading(true));
      let data = await getProjectTeamList(props.projectData?.id);
      console.log(data.users)
      setTeamData(data.users);
      dispatch(setLoading(false));
    } catch (e: any) {
      dispatch(setLoading(false));
    }
  }

  useEffect(() => {
    setTasks(props.formData.tasks);
    getScale();
    getTeamData();
    let { minDate, maxDate }: any = getMinMaxDate();
    setStartrangedate(minDate)
    setEndrangedate(maxDate)
    console.log(props.formData.tasks)
  }, [props.formData.tasks])


  async function onStatusSave(data) {
    let { status } = data;
    try {
      dispatch(setLoading(true));
      let taskResponse: any;
      if (status === "In Progress") {
        let taskUpdateObj = {
          "id": data.id,
          "task_name": data.task_name,
          "task_desc": data.task_desc,
          "planned_duration": Number(data.planned_duration),
          "planned_duration_unit": data.planned_duration_unit,
          "planned_start_date": data.planned_start_date,
          "planned_end_date": data.planned_end_date,
          "status": data.status,
          "actual_start_date": new Date().toISOString(),
          "assigned_to": data.assigned_to ? data.assigned_to : null,
          "assignee_name": data.assignee_name ? data.assignee_name : null
        };

        taskResponse = await updateTask(taskUpdateObj);
      } else if (status === "Completed") {
        if (!data.actual_start_date) {
          dispatch(setLoading(false));
          return;
        }
        let taskUpdateObj = {
          "id": data.id,
          "task_name": data.task_name,
          "task_desc": data.task_desc,
          "planned_duration": Number(data.planned_duration),
          "planned_duration_unit": data.planned_duration_unit,
          "planned_start_date": data.planned_start_date,
          "planned_end_date": data.planned_end_date,
          "status": data.status,
          "actual_start_date": data.actual_start_date,
          "actual_end_date": new Date().toISOString(),
          "assigned_to": data.assigned_to ? data.assigned_to : null,
          "assignee_name": data.assignee_name ? data.assignee_name : null
        };
        console.log("comoleyed taskre ", taskUpdateObj)

        taskResponse = await updateTask(taskUpdateObj);
      } else {
        let taskUpdateObj = {
          "id": data.id,
          "task_name": data.task_name,
          "task_desc": data.task_desc,
          "planned_duration": Number(data.planned_duration),
          "planned_duration_unit": data.planned_duration_unit,
          "planned_start_date": data.planned_start_date,
          "planned_end_date": data.planned_end_date,
          "status": data.status,
          "actual_start_date": null,
          "actual_end_date": null,
          "assigned_to": data.assigned_to ? data.assigned_to : null,
          "assignee_name": data.assignee_name ? data.assignee_name : null
        };
        taskResponse = await updateTask(taskUpdateObj);
      }
      console.log(taskResponse);
      if (taskResponse.success) {
        let response = await getTask(data.id);
        let updatedTask = response.data;
        console.log("updated tasjk", updatedTask)
        setTasks(prevTasks => prevTasks.map(task =>
          task.id === data.id ? updatedTask : task
        ));
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }



  }

  async function onAssignedToSave(data) {
    // console.log(data)
    try {
      dispatch(setLoading(true));
      let taskUpdateObj = {
        "id": data.id,
        "task_name": data.task_name,
        "task_desc": data.task_desc,
        "planned_duration": Number(data.planned_duration),
        "planned_duration_unit": data.planned_duration_unit,
        "planned_start_date": data.planned_start_date,
        "planned_end_date": data.planned_end_date,
        "status": data.status ? data.status : null,
        "actual_start_date": data.actual_start_date ? data.actual_start_date : null,
        "actual_end_date": data.actual_end_date ? data.actual_end_date : null,
        "assigned_to": data.assigned_to ? data.assigned_to : null,
        "assignee_name": data.assignee_name ? data.assignee_name : null
      };

      let taskResponse = await updateTask(taskUpdateObj);
      console.log(taskResponse)
      if (taskResponse.success) {
        let response = await getTask(data.id);
        let updatedTask = response.data;
        console.log("updated tasjk", updatedTask)
        setTasks(prevTasks => prevTasks.map(task =>
          task.id === data.id ? updatedTask : task
        ));
      }
      dispatch(setLoading(false));
    } catch (e: any) {
      dispatch(setLoading(false));
    }
  }

  function getActualBarColors(data) {
    // console.log(data);
    const green = "#4E9F3D";
    const yellow = "#F6C90E";
    const red = "#D21312";
    const lightGray = "#D3D3D3";
    const darkGray = "#A9A9A9";

    const { status, plannedStart, plannedEnd, actualStart, actualEnd } = data;
    const today = new Date();

    // Calculate the total duration between the planned start and end dates
    const totalDuration = plannedEnd - plannedStart;

    // Calculate 20% of the total duration
    const delayThreshold = totalDuration * 0.2;

    // Calculate the date for 20% of the total duration from the planned start date
    const delayFromStartDate = new Date(plannedStart.getTime() + delayThreshold);
    // Calculate the date for 20% of the total duration from the planned end date
    const delayFromEndDate = new Date(plannedEnd.getTime() + delayThreshold);

    if (!status || status === "Not Started") {
      if (today <= plannedStart) {
        return green;
      }
      else if (today > plannedStart && today <= delayFromStartDate) {
        return yellow;
      }
      else {
        return red;
      }
    } else if (status && status === "In Progress") {
      if (actualStart <= plannedStart) {
        return green;
      }
      else if (actualStart > plannedStart && actualStart <= delayFromStartDate) {
        return yellow;
      }
      else if (actualStart > plannedStart && actualStart > delayFromStartDate) {
        return red;
      }
      return green;
    } else if (status && status === "Completed") {
      if (actualEnd <= plannedEnd) {
        return green;
      } else if (actualEnd > plannedEnd && actualEnd <= delayFromEndDate) {
        return lightGray;
      } else if (actualEnd > plannedEnd && actualEnd > delayFromEndDate) {
        return darkGray;
      }
      return green;
    }
    return green;

  }

  function getMinMaxDate() {
    const dates = props.formData.tasks.flatMap(task => {
      const plannedStartDate = new Date(task.planned_start_date).getTime();
      const plannedEndDate = new Date(task.planned_end_date).getTime();
      const actualStartDate = task.actual_start_date ? new Date(task.actual_start_date).getTime() : null;
      const actualEndDate = task.actual_end_date ? new Date(task.actual_end_date).getTime() : null;

      return [plannedStartDate, plannedEndDate, actualStartDate, actualEndDate].filter(date => date !== null)
    });

    if (dates.length === 0) {
      console.error('No valid dates found');
      return null;
    }

    const minDate = new Date(Math.min(...dates));
    const maxDate = new Date(Math.max(...dates));
    return { minDate, maxDate };
  }

  const handleScaleChange = (value) => {
    if (value && value.value) {
      const newScale = value.value;
      if (newScale === "days") {
        let { minDate: startdate }: any = getMinMaxDate();

        let enddate = new Date(startdate);
        // enddate.setMonth(enddate.getMonth() + 1);

        // Set the start and end range dates
        setStartrangedate(new Date(startdate));
        setEndrangedate(new Date(enddate));
      }
      if (newScale === "months") {
        let { minDate, maxDate }: any = getMinMaxDate();
        setStartrangedate(minDate)
        // console.log(minDate)
        let endDate = new Date(minDate);
        let targetMonth = endDate.getMonth() + 3;
        endDate.setMonth(targetMonth);
        setEndrangedate(endDate);
        // console.log(endDate)
      }
      setScale(newScale);
    }
  };

  const downloadChartSvg = (value) => {

    setActualBarsVisible(value)
    setTimeout(() => {
      const svgElement: any = document.querySelector('.gantt-chart-download svg');

      const serializer = new XMLSerializer();
      const svgString = serializer.serializeToString(svgElement);

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();

      const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
      const url = URL.createObjectURL(svgBlob);

      if (!ctx) {
        console.error('Failed to get canvas context.');
        return;
      }

      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        const pngFile = canvas.toDataURL('image/png');
        const downloadLink = document.createElement('a');
        downloadLink.href = pngFile;
        downloadLink.download = 'gantt-chart.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        URL.revokeObjectURL(url);
      };

      img.src = url;
      setActualBarsVisible(true);
    }, 100);
  }

  const downloadChart = async (value) => {
    setActualBarsVisible(value)
    const element: any = downloadChartRef.current;
    if (!element) return;

    async function download() {
      try {
        // Store original overflow settings
        const originalOverflow = element.style.overflow;
        const originalHeight = element.style.height;
        const originalWidth = element.style.width;

        // Temporarily change overflow to visible and set full dimensions
        element.style.overflow = 'visible';
        element.style.height = 'auto';
        element.style.width = 'auto';

        const canvas = await html2canvas(element, {
          logging: false,
          useCORS: true,
          scale: window.devicePixelRatio,
          windowWidth: element.scrollWidth,
          windowHeight: element.scrollHeight
        });

        // Restore original overflow settings
        element.style.overflow = originalOverflow;
        element.style.height = originalHeight;
        element.style.width = originalWidth;

        // Convert the canvas to a data URL
        const dataUrl = canvas.toDataURL('image/png');

        // Create a temporary link element
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'chart_screenshot.png';

        // Trigger the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      catch (error) {
        console.error('Error capturing screenshot:', error);
      }
    }
    setTimeout(() => {
      download()
      setActualBarsVisible(true);
    }, 100);
  }

  const fullPlanClick = () => {
    setStartrangedate(new Date(props.formData.plan_start_date));
    setEndrangedate(new Date(props.formData.plan_end_date));
  }

  const prevButtonClick = () => {
    let startDate = new Date(startrangedate);
    let newStartDate;
    let newEndDate;

    switch (scale) {
      case 'years':
        newStartDate = new Date(startDate.setFullYear(startDate.getFullYear() - 1));
        if (newStartDate < new Date(props.formData.plan_start_date)) {
          newStartDate = new Date(props.formData.plan_start_date);
        }
        newEndDate = new Date(newStartDate);
        break;
      case 'months':
        startDate = new Date(startDate.getFullYear(), startDate.getMonth() - 4, startDate.getDate());

        if (startDate < new Date(props.formData.plan_start_date)) {
          startDate = new Date(props.formData.plan_start_date);
        }

        newStartDate = new Date(startDate);

        newEndDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, startDate.getDate());
        break;
      case 'days':
        newStartDate = new Date(startDate.setMonth(startDate.getMonth() - 1));
        if (newStartDate < new Date(props.formData.plan_start_date)) {
          newStartDate = new Date(props.formData.plan_start_date);
        }
        newEndDate = new Date(newStartDate.setMonth(newStartDate.getMonth()));
        break;
      default:
        console.error("Invalid scale provided.");
        return;
    }

    let startdaterange = newStartDate;
    let enddaterange = newEndDate;
    setStartrangedate(startdaterange);
    setEndrangedate(enddaterange);
  }

  const nextButtonClick = () => {
    const endDate = new Date(endrangedate);
    let startDate = new Date(endDate);
    let newStartDate = new Date(endDate);
    let newEndDate;
    let startYear = new Date(startrangedate)

    switch (scale) {
      case 'years':
        newEndDate = new Date(startYear.setFullYear(startYear.getFullYear() + 1));
        if (newEndDate > new Date(props.formData.plan_end_date)) {
          newEndDate = new Date(props.formData.plan_end_date);
        }
        newStartDate = newEndDate
        break;
      case 'months':
        newStartDate = new Date(startDate.setMonth(startDate.getMonth()));
        newEndDate = new Date(startDate.setMonth(startDate.getMonth() + 3));
        if (newEndDate > new Date(props.formData.plan_end_date)) {
          newEndDate = new Date(props.formData.plan_end_date);
          newStartDate = new Date(newEndDate);
          newStartDate.setMonth(newStartDate.getMonth() - 3);
        }
        break;
      case 'days':
        newStartDate = new Date(startDate.setMonth(startDate.getMonth() + 1));
        newEndDate = newStartDate
        if (newEndDate > new Date(props.formData.plan_end_date)) {
          newStartDate = new Date(props.formData.plan_end_date);
          newEndDate = new Date(props.formData.plan_end_date);
        }
        break;
      default:
        console.error("Invalid scale provided.");
        return;
    }
    let startdaterange = newStartDate;
    let enddaterange = newEndDate;

    setStartrangedate(startdaterange)
    setEndrangedate(enddaterange)
  }

  function validDate(date) {
    let startDate: any = new Date(date);
    if (isNaN(startDate)) {
      return startDate;
    }
    else {
      return startDate.toISOString();
    }
  }

  const setActualDates = async () => {
    setaction("");
    console.log(values);
    console.log(currentSelectedTask);
    let data = currentSelectedTask;
    let newStatus;
    if(values.actualstartdate && values.actualenddate){
      newStatus = "Completed";
    }
    else if(values.actualstartdate){
      newStatus = "In Progress";
    }else{
      newStatus = "Not Started";
    }
    try {
      dispatch(setLoading(true));
      let taskUpdateObj = {
        "id": data.id,
        "task_name": data.task_name,
        "task_desc": data.task_desc,
        "planned_duration": Number(data.planned_duration),
        "planned_duration_unit": data.planned_duration_unit,
        "planned_start_date": data.planned_start_date,
        "planned_end_date": data.planned_end_date,
        "status": newStatus,
        "actual_start_date": values.actualstartdate ? validDate(values.actualstartdate) : null,
        "actual_end_date": values.actualenddate ? validDate(values.actualenddate) : null,
        "assigned_to": data.assigned_to ? data.assigned_to : null,
        "assignee_name": data.assignee_name ? data.assignee_name : null
      };
      console.log(taskUpdateObj);
      let taskResponse = await updateTask(taskUpdateObj);
      console.log(taskResponse);
      if (taskResponse.success) {
        let response = await getTask(data.id);
        let updatedTask = response.data;
        setCurrentSelectedTask(updatedTask);
        console.log("updated tasjk", updatedTask)
        setTasks(prevTasks => prevTasks.map(task =>
          task.id === data.id ? updatedTask : task
        ));
      }
      showToaster(toasterTypes.SUCCESS, "Actual start and end dates updated successfully");
      dispatch(setLoading(false));
    } catch (e: any) {
      dispatch(setLoading(false));
      showToaster(toasterTypes.ERROR, "Failed to updated Actual dates");
    }
  }

  useEffect(()=>{
      updateValue({
        actualstartdate : currentSelectedTask.actualStart ? currentSelectedTask.actualStart : null,
        actualenddate : currentSelectedTask.actualEnd ? currentSelectedTask.actualEnd : null
      })
  },[currentSelectedTask])

  return (
    <>
      {action === 4 && (
        <ModalComponent
          width={"350px"}
          isOpen={true}
          onClose={() => setaction("")}
          header={"Download Option"}
          centered={true}
          customClass="gantt-download-modal"
          body={
            <>
              <div className="gantt-download">
                <FieldItem
                  {...FormElements.formTypes}
                  value={values.formType}
                  onChange={(...e) =>
                    handleChange(FormElements.formTypes.name, ...e)
                  }
                  touched={fields.formTypes && fields.formTypes.hasError}
                  error={fields.formTypes && fields.formTypes.errorMsg}
                />
              </div>
            </>
          }
          footer={<></>}
        />
      )}
      {action == "setActualDates" &&(
        <ModalComponent
        width={"350px"}
        isOpen={true}
        onClose={() => setaction("")}
        header={"Set Actual Start and End Date"}
        centered={true}
        customClass="set-actual-dates-modal"
        body={
          <>
            <div className="set-actual-dates">
              <Col>
              <FieldItem
                {...FormElements.actualstartdate}
                value={values.actualstartdate}
                onChange={(...e) =>
                  handleChange(FormElements.actualstartdate.name, ...e)
                }
                touched={fields.startdate && fields.startdate.hasError}
                error={fields.startdate && fields.startdate.errorMsg}
              />
            </Col>
            <Col>
              <FieldItem
                {...FormElements.actualenddate}
                value={values.actualenddate}
                onChange={(...e) =>
                  handleChange(FormElements.actualenddate.name, ...e)
                }
                touched={fields.enddate && fields.enddate.hasError}
                error={fields.enddate && fields.enddate.errorMsg}
              />
            </Col>
            </div>
          </>
        }
        footer={
          <div>
            <CustomButton
              type="primary"
              isDisabled={props.isView}
              text="Save"
              onClick={() => setActualDates()}
            ></CustomButton>
          </div>
        }
      />
      )}

      <div className='viewganttchart-container'>
        <div className='viewganttchart-header'>
          <div className='header-text'>Gantt Chart</div>
          <div className='header-buttons'>
            <i className='icon icon-download' onClick={() => setaction(4)} />
            <i className='icon icon-close' onClick={() => props.onClose()} />
          </div>
        </div>
        <Row style={{
          marginTop: '20px',
          paddingLeft: '20px',
          alignItems: 'center',
          justifyContent:'center',
          position: 'relative',
          zIndex: '999'
        }}>
          <Col md={2} style={{
            marginTop: '-5px'
          }}>
            <FieldItem
              {...CreateFormElements.scaleDropdown}
              value={{ value: scale, label: scale.charAt(0).toUpperCase() + scale.slice(1).toLowerCase() }}
              values={CreateFormElements.scaleDropdown.values}
              onChange={(...e) => {
                handleScaleChange(...e)
              }}
            />

          </Col>
          <Col md={2} style={{
            paddingLeft: '0',
            paddingRight: '0',
            marginRight: '20px'
          }}>
            <FieldItem
              {...CreateFormElements.startrange}
              value={startrangedate}
              onChange={(...e) =>
                handleChange(CreateFormElements.startrange.name, ...e)
              }
            />
          </Col>
          <Col md={2} style={{
            paddingLeft: '0',
            paddingRight: '0',
            marginRight: '20px'
          }}>
            <FieldItem
              {...CreateFormElements.endrange}
              value={endrangedate}
              onChange={(...e) =>
                handleChange(CreateFormElements.endrange.name, ...e)
              }
            />
          </Col>
          <Col style={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '0',
            paddingRight: '0',
            flexGrow: '0'
          }}>
            <CustomButton type="primary" text="Full Plan" onClick={fullPlanClick} />
          </Col>
          <Col style={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '0',
            paddingRight: '0',
            flexGrow: '0'
          }}>
            <CustomButton type="primary" text="Prev" onClick={prevButtonClick} />
          </Col>
          <Col style={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '0',
            paddingRight: '0',
            flexGrow: '0'
          }}>
            <CustomButton type="primary" text="Next" onClick={nextButtonClick} />
          </Col>
        </Row>
        <div className='ganttchart' style={{
          marginTop: `${window.innerWidth > 700 ? '50px' : '50px'}`,
        }}>


          <GanttChart data={tasks} userData={props.userData} scale={scale} setScale={setScale} onStatusSave={onStatusSave} teamData={teamData} onAssignedToSave={onAssignedToSave} getActualBarColors={getActualBarColors} actualBarsVisible={actualBarsVisible} startrangedate={startrangedate} endrangedate={endrangedate} downloadChartRef={downloadChartRef} setaction={setaction} setCurrentSelectedTask={setCurrentSelectedTask}/>

        </div>
      </div>
    </>
  )
}

export default ViewGanttChart